export enum NotificationChannels {
    Database = 'database',
    Broadcast = 'broadcast',
    Mail = 'mail'
}

export enum NotificationTypes {
    Ticket = 'ticket',
    TicketCommentAdded = 'ticket_comment-added',
    TicketAssigned = 'ticket_assigned',
    Device = 'device',
    Scene = 'scene',
    SceneDesignChanged = 'scene_design-changed',
    SceneDevicesChanged = 'scene_device-changed',
}

export class Notifications {
    id: string;
    type: string;
    data:  any;
    read_at: Date;
    created_at: Date;
    updated_at: Date;
}

export class NotificationMarkRead {
    status: boolean;
}

export class NotificationConfig {
    status: boolean;
}

export class NotificationPreferences {
    type: string;
    channel:  NotificationChannels[];
    created_at: Date;
    updated_at: Date;
}

