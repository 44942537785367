import {Component, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {AwsCognitoAuthService} from '../../core/services/aws-cognito-auth.service';
import {environment} from '../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import { UserResponse, UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models/auth.models';
import {NotificationService} from "../../core/services/notification.service";
import {Notifications, NotificationTypes} from "../../core/models/notification.model";
import {Channel} from "laravel-echo/src/channel";
import {WebsocketService} from "../../core/services/websocket.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

    element;
    cookieValue;
    flagvalue;
    countryName;
    valueset;

    listLang = [
        { text: 'English', flag: 'assets/images/flags/uk-us.png', lang: 'en' },
    ];

    openMobileMenu: boolean;
    user: User;
    notifications: Notifications[];
    notificationsChannel: Channel = null;

    constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AwsCognitoAuthService,
                public languageService: LanguageService,
                public translate: TranslateService,
                public _cookiesService: CookieService,
                private userService: UserService,
                public webSocket: WebsocketService,
                private notificationService: NotificationService) {
    }

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    ngOnInit() {
        this.openMobileMenu = false;
        this.element = document.documentElement;

        // User's info
        this.user = new User();
        this.userService.getCurrentUser()
            .subscribe((data) => {
                if (data) {
                    this.user = data['data'];

                    // Look for updates
                    this.notificationsChannel = this.webSocket.client.private('App.Data.Models.User.' + this.user.id)
                        .notification((notification) => {
                            this.notificationService.get(true).subscribe((data) => {
                                if(data){
                                    this.notifications = data.data;
                                }
                            });
                        });
                }
            });

        // Users notifications
        this.notificationService.get(true).subscribe((data) => {
            if(data){
                this.notifications = data.data;
            }
        });

        // Set lang
        this.cookieValue = this._cookiesService.get('lang');
        const val = this.listLang.filter(x => x.lang === this.cookieValue);
        this.countryName = val.map(element => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.valueset = 'assets/images/flags/uk-us.png';
            }
        } else {
            this.flagvalue = val.map(element => element.flag);
        }
    }

    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.cookieValue = lang;
        this.languageService.setLanguage(lang);
    }

    /**
     * Toggles the right sidebar
     */
    // toggleRightSidebar() {
    //     this.settingsButtonClicked.emit();
    // }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        if (environment.defaultauth === 'awsCognito') {
            this.authService.logout();
        }
        this.router.navigate(['/account/login']);
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement && !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    public get notificationTypes(): typeof NotificationTypes {
        return NotificationTypes;
    }

    markRead(uuid: string){
        this.notificationService.markRead(uuid).subscribe((data) => {
            this.notifications = [];
        });
    }

    sourcetTypeToLink(source: string): string
    {
        return '/' + source.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());
    }
}
