import { Pipe, PipeTransform } from '@angular/core';
import * as semver from 'semver';

@Pipe({
    name: 'semverGt'
})
export class SemverGtPipe implements PipeTransform {

    constructor() {
    }

    transform(v1: any, v2: string): boolean {
        if (v1 === undefined)
            return false;

        return (semver.gt(v1, v2));
    }
}
